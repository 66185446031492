import * as React from "react";
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  Flex,
  Heading,
  Container,
  Image,
  Button,
  HStack,
  DarkMode,
} from "@chakra-ui/react";
import { theme } from "./theme";

export const App = () => (
  <ChakraProvider theme={theme}>
    <DarkMode>
      <Container maxW="6xl" justifyContent={"start"} alignItems={"start"}>
        <Text py={4}>Slider.fi</Text>
        <Flex
          w="full"
          h={"90vh"}
          flexDir={{ base: "column", lg: "row" }}
          alignItems="center"
          justifyContent={{ base: "start", lg: "space-between" }}
          fontSize="xl"
        >
          <VStack
            alignItems={"start"}
            mx={{ base: 10, lg: 0 }}
            mt={{ base: 20, lg: 0 }}
            gap={8}
          >
            <Heading
              fontWeight={"900"}
              fontSize={{ base: "5xl", md: "6xl" }}
              lineHeight={1.05}
            >
              Use any asset
              <br />
              on any chain
              <br />
              on your dapp.
            </Heading>
            <HStack>
              <Button
                bgColor={"blue.600"}
                _hover={{ bgColor: "blue.700" }}
                fontWeight={"500"}
                onClick={
                  () =>
                    window.open(
                      "https://devfolio.co/projects/xframe-0904",
                      "_blank"
                    )
                  // eslint-disable-next-line react/jsx-curly-newline
                }
              >
                View on Devfolio
              </Button>
              <Button colorScheme="gray" fontWeight={"500"} isDisabled={true}>
                NPM Package 🔜
              </Button>
            </HStack>
          </VStack>
          <Image src={"/demo.png"} w={"full"} maxW={"500px"} />
        </Flex>
      </Container>
    </DarkMode>
  </ChakraProvider>
);
